<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="verificationOpen">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="verificationOpen = false"
      :open="verificationOpen"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle
              max-w-4xl
              w-full
              h-screen-iframe
            "
          >
            <div
              v-if="webJourneyUrl"
              class="relative h-screen-iframe"
              style="padding-top: 56.25%"
            >
              <iframe
                allow="camera *;microphone *"
                :src="webJourneyUrl"
                class="absolute inset-0 w-full h-full"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <div v-if="client.id" class="flex flex-col h-full">
    <div class="-my-2 sm:-mx-6 lg:-mx-8 h-full">
      <nav
        class="flex mb-8 justify-between sm:px-6 lg:px-8 lg:h-10 md:h-10"
        aria-label="Breadcrumb"
      >
        <ol class="flex items-center space-x-4">
          <li>
            <div>
              <router-link to="/" class="text-gray-400 hover:text-gray-500">
                <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span class="sr-only">Home</span>
              </router-link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <router-link
                :to="{ name: 'clients' }"
                class="
                  ml-4
                  text-sm
                  font-medium
                  text-gray-500
                  hover:text-gray-700
                "
                >Clients
              </router-link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <router-link
                :to="{ name: 'client', params: { id: client.id } }"
                class="
                  ml-4
                  text-sm
                  font-medium
                  text-gray-500
                  hover:text-gray-700
                "
                >{{ client.info?.first_name }}
              </router-link>
            </div>
          </li>
        </ol>
      </nav>
      <div
        class="
          py-2
          align-middle
          inline-block
          min-w-full
          sm:px-6
          lg:px-8
          bg-gray-50
          lg:mt-16
          md:mt-12
          h-full
        "
      >
        <article>
          <!-- Profile header -->
          <div>
            <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <div class="sm:flex sm:items-end sm:space-x-5">
                <div class="flex w-full">
                  <img
                    class="
                      h-24
                      w-24
                      rounded-full
                      ring-4 ring-white
                      sm:h-32 sm:w-32
                      lg:-translate-y-16
                      md:-translate-y-12
                      transform
                    "
                    :src="`https://source.boringavatars.com/beam/120/${client.email}?colors=0C4A6E,0284C7,D1FAE5,0284C7,e76f51`"
                    alt=""
                  />
                  <div class="flex items-center justify-between w-full">
                    <p class="ml-5 text-2xl font-bold text-gray-900">
                      {{ client.info?.first_name }} {{ client.info?.last_name }}
                    </p>
                    <span
                      class="
                        relative
                        z-0
                        inline-flex
                        shadow-sm
                        rounded-md
                        self-center
                      "
                    >
                      <button
                        type="button"
                        class="
                          relative
                          inline-flex
                          items-center
                          px-4
                          py-2
                          rounded-l-md
                          border border-blue-300
                          bg-white
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-blue-50
                          focus:z-10
                          focus:outline-none
                          focus:ring-1
                          focus:ring-blue-500
                          focus:border-blue-500
                        "
                      >
                        <ChatIcon
                          class="flex-shrink-0 h-5 w-5 text-blue-500 mr-3"
                        />
                        Message
                      </button>
                      <button
                        type="button"
                        class="
                          relative
                          inline-flex
                          items-center
                          px-4
                          py-2
                          border border-blue-300 border-l-0
                          bg-white
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-blue-50
                          focus:z-10
                          focus:outline-none
                          focus:ring-1
                          focus:ring-blue-500
                          focus:border-blue-500
                        "
                      >
                        <PhoneIcon
                          class="flex-shrink-0 h-5 w-5 text-blue-500 mr-3"
                        />
                        Call
                      </button>
                      <Menu as="div" class="relative inline-block text-left">
                        <MenuButton>
                          <button
                            type="button"
                            class="
                              -ml-px
                              relative
                              inline-flex
                              items-center
                              px-4
                              py-2
                              rounded-r-md
                              border border-blue-300
                              bg-white
                              text-sm
                              font-medium
                              text-gray-700
                              hover:bg-blue-50
                              focus:z-10
                              focus:outline-none
                              focus:ring-1
                              focus:ring-blue-500
                              focus:border-blue-500
                            "
                          >
                            <DotsVerticalIcon
                              class="h-5 w-5 text-blue-500"
                              aria-hidden="true"
                            />
                          </button>
                        </MenuButton>
                        <transition
                          enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95"
                        >
                          <MenuItems
                            class="
                              origin-top-right
                              absolute
                              right-0
                              mt-2
                              w-56
                              rounded-md
                              shadow-lg
                              bg-white
                              ring-1 ring-black ring-opacity-5
                              divide-y divide-gray-100
                              focus:outline-none
                            "
                          >
                            <div class="py-1">
                              <MenuItem v-slot="{ active }">
                                <a
                                  href="#"
                                  :class="[
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'group flex items-center px-4 py-2 text-sm',
                                  ]"
                                >
                                  <PencilAltIcon
                                    class="
                                      mr-3
                                      h-5
                                      w-5
                                      text-gray-400
                                      group-hover:text-gray-500
                                    "
                                    aria-hidden="true"
                                  />
                                  Edit
                                </a>
                              </MenuItem>
                              <MenuItem v-slot="{ active }">
                                <a
                                  href="#"
                                  :class="[
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'group flex items-center px-4 py-2 text-sm',
                                  ]"
                                >
                                  <ArchiveIcon
                                    class="
                                      mr-3
                                      h-5
                                      w-5
                                      text-gray-400
                                      group-hover:text-gray-500
                                    "
                                    aria-hidden="true"
                                  />
                                  Archive
                                </a>
                              </MenuItem>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div
                  class="
                    flex
                    lg:justify-between
                    md:justify-between
                    gap-y-4
                    flex-wrap
                  "
                >
                  <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Client Information
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Personal details and application.
                    </p>
                  </div>
                </div>
                <div class="mt-5 border-t border-gray-200">
                  <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm font-medium text-gray-500">Name</dt>
                      <dd
                        class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                      >
                        {{ client.info?.first_name }}
                        {{ client.info?.last_name }}
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm font-medium text-gray-500">Email</dt>
                      <dd
                        class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                      >
                        {{ client.email }}
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm font-medium text-gray-500">Address</dt>
                      <dd
                        class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                      >
                        {{ client.info?.address?.street_address }}
                        {{ client.info?.address?.country }}
                        {{ client.info?.address?.city }}
                        {{ client.info?.address?.state }}
                        {{ client.info?.address?.postcode }}
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm font-medium text-gray-500">Email</dt>
                      <dd
                        class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                      >
                        {{ client.email }}
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm font-medium text-gray-500">
                        Phone Number
                      </dt>
                      <dd
                        class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                      >
                        {{ client.info?.phone }}
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm font-medium text-gray-500">
                        Identity Verification
                      </dt>
                      <dd
                        class="
                          mt-1
                          text-sm text-gray-900
                          sm:mt-0 sm:col-span-2
                          flex
                          gap-2
                        "
                      >
                        <span
                          :class="
                            client.verify_status == 'verifed'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          "
                          class="
                            px-2
                            flex
                            text-xs
                            leading-5
                            max-w-max
                            font-semibold
                            rounded-full
                          "
                        >
                          {{ client.verify_status }}
                        </span>
                        <button
                          @click="handleVerificationClick"
                          class="text-blue-600 font-medium inline-block"
                        >
                          Show Verification Result
                        </button>
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm font-medium text-gray-500">Case</dt>
                      <dd
                        class="
                          mt-1
                          text-sm text-gray-900
                          sm:mt-0 sm:col-span-2
                          grid
                          gap-3
                        "
                      >
                        <template :key="cases.id" v-for="cases in client.cases">
                          <router-link
                            :to="{ name: 'case', params: { id: cases.id } }"
                            :class="
                              cases.info.app_type.uid === 'sale'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                            "
                            class="
                              px-2
                              flex
                              text-xs
                              leading-5
                              max-w-max
                              font-semibold
                              rounded-full
                            "
                          >
                            {{ cases.info.app_type.name }} of
                            {{ cases.info.address.street_address }}
                          </router-link>
                        </template>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChevronRightIcon,
  HomeIcon,
  ChatIcon,
  PencilAltIcon,
  DotsVerticalIcon,
  PhoneIcon,
  ArchiveIcon,
} from "@heroicons/vue/solid";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";
import { getVerificationResult } from "../../../../services/auth";
import { useToast } from "vue-toastification";

export default {
  components: {
    ChevronRightIcon,
    HomeIcon,
    PencilAltIcon,
    ChatIcon,
    PhoneIcon,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ArchiveIcon,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const verificationOpen = ref(false);
    const webJourneyUrl = ref("");
    const toast = useToast();

    const handleVerificationClick = async () => {
      const registration_id = store.state.user.client.registration_id;
      if (registration_id) {
        const {
          data: {
            results: [res],
          },
        } = await getVerificationResult(
          store.state.user.client.registration_id
        );
        webJourneyUrl.value = res.url;
        verificationOpen.value = true;
      } else {
        toast.warning("Registration ID couldn't found");
      }
    };

    onMounted(() => store.dispatch("user/getClient", route.params.id));

    return {
      client: computed(() => store.state.user.client),
      handleVerificationClick,
      verificationOpen,
      webJourneyUrl,
    };
  },
};
</script>

